'use client'

import { FC, useEffect, useState } from 'react'
import { SectionArticlesLandingPageProps } from '@portfolio/models'
import { styled } from '@portfolio/styles'
import {
  Chip,
  Container,
  Link,
  Media,
  SectionWrapper,
  TagsGroup,
} from '@portfolio/components'
import { useTranslation } from 'react-i18next'

const StyledSectionWrapper = styled(SectionWrapper, {
  marginBottom: '$mobileSectionMarginL',
  '@lg': {
    marginBottom: '$desktopSectionMarginL',
  },
})

const Title = styled('h1', {
  projectFont: 'heading01',
  textAlign: 'center',
  marginBottom: '$96',
})

const ArticlesGrid = styled('div', {
  margin: '0 auto',
  display: 'grid',
  gridTemplateColumns: '1fr',
  gap: '$56',
  maxWidth: '100%',

  '@md': {
    gridTemplateColumns: 'repeat(3, 1fr)',
    gap: '$64 $24',
  },
})

const Article = styled('div', {
  position: 'relative',
  width: '100%',
})

const ArticleTitle = styled('h2', {
  projectFont: 'heading07',
  gridRow: 2,
  gridColumn: 1,
})

const ArticleGridContent = styled('div', {
  position: 'relative',
  display: 'grid',
  rowGap: '$24',
  width: '100%',
  paddingTop: '$20',

  '@md': {
    rowGap: '$32',
  },
})

const MediaWrap = styled('div', {
  gridColumn: 1,
  gridRow: 1,
  borderRadius: '$r2',
  overflow: 'hidden',
  transition: 'transform .5s ease',

  '& img': {
    transition: 'transform .5s ease',
  },

  '@md': {
    '&:hover': {
      '& img': {
        transform: 'scale(1.04)',
      },
    },
  },
})

const ArticleTags = styled('div', {
  display: 'flex',
  flexWrap: 'wrap',
  gridColumn: 1,
  gridRow: 1,
  columnGap: '$12',
})

const ArticleTag = styled('div', {
  display: 'flex',
  flexWrap: 'wrap',
  gap: '10px',
})

const StyledLink = styled(Link, {
  zIndex: '$content',
  projectFont: 'heading08',
  textDecoration: 'underline',
  gridColumn: 1,
  gridRow: 3,
  textUnderlineOffset: '.35em',
})

export const SectionArticlesLandingPage: FC<
  SectionArticlesLandingPageProps
> = ({ title, articles, priority }) => {
  const { t } = useTranslation('articles')
  const [selectedTag, setSelectedTag] = useState<string | null>(null)
  const [filteredArticles, setFilteredArticles] = useState(articles)

  const tags = articles?.reduce<{ title: string; value: string }[]>(
    (acc, article) => {
      if (!article.tags) return acc

      article.tags.forEach((tag) => {
        if (!acc.some((existingTag) => existingTag.value === tag.value)) {
          acc.push(tag)
        }
      })

      return acc
    },
    [],
  )

  useEffect(() => {
    if (!selectedTag) {
      setFilteredArticles(articles)
      return
    }

    setFilteredArticles(
      articles?.filter((article) =>
        article.tags?.some((tag) => tag.value === selectedTag),
      ),
    )
  }, [selectedTag, articles])

  return (
    <StyledSectionWrapper type="FIRST_SECTION">
      {title && <Title>{title}</Title>}
      {tags && (
        <TagsGroup
          tags={tags}
          selectedTag={selectedTag}
          setSelectedTag={setSelectedTag}
        />
      )}
      <Container>
        <ArticlesGrid>
          {filteredArticles &&
            filteredArticles.map((article, index) => (
              <Article key={article.path}>
                <a
                  href={article.path}
                  tabIndex={-1}
                  aria-label={`Article - ${article.title}`}
                >
                  {article.thumbnail && (
                    <MediaWrap>
                      <Media
                        {...article.thumbnail}
                        hardcropRatio={'landscape3'}
                        priority={priority && index < 3}
                        sizes="(min-width: 740px) 50vw, 100vw"
                      />
                    </MediaWrap>
                  )}
                </a>
                <ArticleGridContent>
                  {article.tags && (
                    <ArticleTags>
                      <ArticleTag>
                        {article.tags.map((tag) => (
                          <Chip
                            key={tag.value}
                            title={tag.title}
                            type="darkDisabled"
                          />
                        ))}
                      </ArticleTag>
                    </ArticleTags>
                  )}
                  {article.title && (
                    <ArticleTitle>{article.title}</ArticleTitle>
                  )}
                  <StyledLink href={article.path} appearance="text">
                    {t('readArticle')}
                  </StyledLink>
                </ArticleGridContent>
              </Article>
            ))}
        </ArticlesGrid>
      </Container>
    </StyledSectionWrapper>
  )
}
