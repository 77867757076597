'use client'

import { Chip, Media, Container, Link } from '@portfolio/components'
import { SectionArticleRelatedProps } from '@portfolio/models'
import { styled } from '@portfolio/styles'
import { useTranslation } from 'react-i18next'

const ArticlesGrid = styled('div', {
  margin: '0 auto',
  display: 'grid',
  gridTemplateColumns: '1fr',
  gap: '$56',
  padding: '$96 0 $48',

  '@md': {
    gridTemplateColumns: 'repeat(3, 1fr)',
    gap: '$64 $24',
    maxWidth: '100%',
  },
})

const Article = styled('div', {
  position: 'relative',
  width: '100%',
})

const ArticleTitle = styled('h2', {
  projectFont: 'heading07',
  gridRow: 2,
  gridColumn: 1,
})

const ArticleGridContent = styled('div', {
  position: 'relative',
  display: 'grid',
  rowGap: '$24',
  width: '100%',
  paddingTop: '$20',

  '@md': {
    rowGap: '$32',
  },
})

const MediaWrap = styled('div', {
  gridColumn: 1,
  gridRow: 1,
  borderRadius: '$r2',
  overflow: 'hidden',
})

const ReadArticle = styled('span', {
  zIndex: '$content',
  projectFont: 'heading08',
  textDecoration: 'underline',
  gridColumn: 1,
  gridRow: 3,
  textUnderlineOffset: '.35em',
})

const ArticleTags = styled('div', {
  display: 'flex',
  flexWrap: 'wrap',
  gridColumn: 1,
  gridRow: 1,
  columnGap: '$12',
})

const ArticleTag = styled('div', {
  display: 'flex',
  flexWrap: 'wrap',
  gap: '10px',
})

const ContainerTitleWrap = styled('div', {
  padding: '$96 0 0',
  display: 'grid',
  alignItems: 'center',
  justifyContent: 'center',
  gridTemplateColumns: 'auto auto',
  columnGap: '$64',
})

const ContainerTitle = styled('h2', {
  projectFont: 'heading02',
})

const ContainerAllArticlesLink = styled(Link, {
  zIndex: '$content',
  projectFont: 'heading08',
  textDecoration: 'underline',
  textUnderlineOffset: '.35em',
})

export const SectionArticleRelated = ({
  relatedArticles,
  priority,
}: SectionArticleRelatedProps) => {
  const { t } = useTranslation('articles')

  return (
    <Container>
      <ContainerTitleWrap>
        <ContainerTitle>{t('readMore')}</ContainerTitle>
        <ContainerAllArticlesLink appearance="textUnderline" href={`/insights`}>
          {t('allArticles')}
        </ContainerAllArticlesLink>
      </ContainerTitleWrap>
      <ArticlesGrid>
        {relatedArticles &&
          relatedArticles.map((article, index: number) => (
            <a
              href={article.path}
              tabIndex={-1}
              aria-label={`Article - ${article.title}`}
            >
              <Article key={article.path}>
                {article.thumbnail && (
                  <MediaWrap>
                    <Media
                      {...article.thumbnail}
                      hardcropRatio={'landscape3'}
                      priority={priority && index < 3}
                      sizes="(min-width: 740px) 50vw, 100vw"
                    />
                  </MediaWrap>
                )}
                <ArticleGridContent>
                  {article.tags && (
                    <ArticleTags>
                      <ArticleTag>
                        {article.tags.map((tag) => (
                          <Chip
                            key={tag.value}
                            title={tag.title}
                            type="darkDisabled"
                          />
                        ))}
                      </ArticleTag>
                    </ArticleTags>
                  )}
                  {article.title && (
                    <ArticleTitle>{article.title}</ArticleTitle>
                  )}

                  <ReadArticle>{t('readArticle')}</ReadArticle>
                </ArticleGridContent>
              </Article>
            </a>
          ))}
      </ArticlesGrid>
    </Container>
  )
}
